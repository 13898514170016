import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Card,
  Flexbox,
  Heading,
  Link,
  Spacer,
  Text,
} from '@skf-internal/ui-components-react-legacy';

import { IntlShape } from 'helpers/propTypes';

const LCPDecommissioningView = ({ intl }) => {
  const text = intl.formatMessage(
    {
      defaultMessage:
        'Dear SKF LCP User,\n\n'
        + 'We would like to inform you that the SKF LCP platform was officially decommissioned on {dDate} '
        + 'as part of our transition to the new SKF Lubrication Cloud platform. This change is aimed at enhancing '
        + 'your experience with improved features and functionalities.\n',
      id: 'sqVfbc',
    },
    {
      dDate: intl.formatDate(new Date('2024-09-30')),
    }
  );
  const details = [
    intl.formatMessage(
      {
        defaultMessage:
          'After the decommissioning, the SKF LCP platform will no longer be accessible. We encourage you to '
          + 'create an account or access your existing account on the new SKF Lubrication Cloud platform at {orbitLink}',
        id: 'fumLzB',
      },
      {
        orbitLink: (
          <Link as="a" href="https://www.lubrication-cloud.skf.com/">
            https://www.lubrication-cloud.skf.com/
          </Link>
        ),
      }
    ),
    intl.formatMessage({
      defaultMessage:
        'Any existing devices in the previous SKF LCP platform have been migrated, so you can log in using your existing email.',
      id: '+uGMLr',
    }),
    intl.formatMessage({
      defaultMessage:
        'If no devices were assigned to the LCP account you can create a new account for free and access all the new '
        + 'functionalities in Lubrication Cloud.',
      id: '7eAWRq',
    }),
  ];

  const closing = intl.formatMessage(
    {
      defaultMessage:
        'If you have any questions or need assistance, please reach out to our Technical Support Group (TSG) at {tsgLink} '
        + 'We appreciate your understanding and cooperation during this transition. Thank you for being a valued part '
        + 'of our community, and we look forward to continuing to support you on our new platform.\n\n'
        + 'Best regards,\n\n'
        + 'The SKF Lubrication Cloud Team',
      id: 'xDUyeL',
    },
    {
      tsgLink: (
        <Link as="a" href="https://www.skf.com/cm/tsg">
          https://www.skf.com/cm/tsg
        </Link>
      ),
    }
  );
  return (
    <Flexbox
      feAlignItems="center"
      feJustifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Card style={{ width: '42em' }}>
        <Heading as="h3" style={{ textAlign: 'center' }}>
          <FormattedMessage
            defaultMessage="Important Notice: Decommissioning of the SKF LCP Platform"
            id="cj3dzM"
          />
        </Heading>
        <Spacer />
        <Spacer />
        <Text style={{ whiteSpace: 'pre-line' }}>{text}</Text>
        <Spacer />
        <Text feFontWeight="600" style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage defaultMessage="Key Details:" id="7Gc7lr" />
        </Text>
        <ul style={{ padding: '1em 0', margin: '1em', listStyle: 'disc' }}>
          {details.map((detail, i) => (
            <>
              <li key={i} style={{ whiteSpace: 'pre-line' }}>{detail}</li>
              <Spacer />
            </>
          ))}
        </ul>
        <Spacer />
        <Text style={{ whiteSpace: 'pre-line' }}>{closing}</Text>
        <Spacer />
        <Flexbox feJustifyContent="center">
          <Button
            onClick={() => {
              window.location = 'https://www.lubrication-cloud.skf.com/';
            }}
          >
            <FormattedMessage defaultMessage="Lubrication Cloud →" id="7c3piy" />
          </Button>
        </Flexbox>
      </Card>
    </Flexbox>
  );
};

LCPDecommissioningView.propTypes = {
  intl: IntlShape,
};

export default compose(injectIntl)(LCPDecommissioningView);
