/* eslint-disable react/no-unused-prop-types */
import SLoader from 'components/SLoader';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as intlActions from 'redux/modules/intl';
import * as uiActions from 'redux/modules/ui';
import LCPDecommissioningView from 'containers/LCPDecommissioningView';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const render = props => {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

function mapState(state) {
  const {
    intl: {
      translations,
      loading: intlLoading,
      loaded: intlLoaded,
      locale,
      language,
    },
    redirect: { redirectTo },
    notifications,
  } = state;

  // Set moment locale
  moment.locale(locale);

  return {
    locale,
    language,
    translations,
    intlLoading,
    intlLoaded,
    redirectTo,
    notifications,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...intlActions,
        ...uiActions,
      },
      dispatch
    ),
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.handleResize = debounce(() => {
      props.actions.screenResize(window.innerWidth);
    }, 100);
  }

  componentDidMount() {
    if (!this.props.intlLoaded) {
      this.props.actions.loadTranslations(this.props.language);
    }

    // set the initial width
    this.props.actions.screenResize(window.innerWidth);

    // Add event listener
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  redirect(redirectTo) {
    if (redirectTo.indexOf('http') === 0) {
      window.location = redirectTo;
      return <SLoader />;
    }

    return (
      <Router>
        <Redirect to={redirectTo} />
      </Router>
    );
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.locale || 'en'}
        messages={this.props.translations}
        textComponent={React.Fragment}
        defaultLocale="en"
      >
        <LCPDecommissioningView />
      </IntlProvider>
    );
  }
}

App.propTypes = {
  actions: PropTypes.shape({
    loadTranslations: PropTypes.func.isRequired,
    screenResize: PropTypes.func.isRequired,
  }),
  translations: PropTypes.object,
  intlLoading: PropTypes.bool,
  intlLoaded: PropTypes.bool,
  locale: PropTypes.string,
  language: PropTypes.string,
  notifications: PropTypes.array,
  redirectTo: PropTypes.string,
};

App.defaultProps = {
  translations: {},
  redirectTo: '',
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapState, mapDispatch)(App);
